<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12" class="mb-4">
      <div class="pa-2 text-center" style="font-size: 28px">
      {{ saludo }} {{ $store.state.usuario.nombre }} {{ $store.state.usuario.apellido }}
    </div>
    </v-col>
    <v-col v-if="dycar" cols="12" sm="6">
      <v-img
        :src="require(`../assets/dycar${$vuetify.theme.dark ? 'dark' : ''}.webp`)"
        height="100"
        contain
      ></v-img>
    </v-col>
    <v-col v-if="euro" cols="12" sm="6">
      <v-img
        :src="require('../assets/eurodycar.webp')"
        height="100"
        contain
      ></v-img>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      euro: false,
      dycar: false,
      saludo: ''
    }
  },
  created () {
    this.euro = this.$store.state.empresas.find(e => e.id == 2 || e.id == 3)
    this.dycar = this.$store.state.empresas.find(e => e.id == 1)
    const hora = moment().hour()
    if (hora >= 0 && hora < 12) {
      this.saludo = 'Buenos días'
    }
    else if (hora >= 12 && hora < 20) {
      this.saludo = 'Buenas tardes'
    }
    else {
      this.saludo = 'Buenas noches'
    }
  }
}
</script>